body {
    background-color: #fff;
}

:root {
    --jumbotron-padding-y: 3rem;
}

.jumbotron {
    padding-top: var(--jumbotron-padding-y);
    padding-bottom: var(--jumbotron-padding-y);
    margin-bottom: 0;
    background-color: #fff;
}
@media (min-width: 768px) {
    .jumbotron {
        padding-top: calc(var(--jumbotron-padding-y) * 2);
        padding-bottom: calc(var(--jumbotron-padding-y) * 2);
    }
}

.jumbotron p:last-child {
    margin-bottom: 0;
}

.jumbotron-heading {
    font-weight: 300;
}

.jumbotron .container {
    max-width: 40rem;
}

footer {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

footer p {
    margin-bottom: .25rem;
}

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }


/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
    margin-bottom: 60px;
}

.carousel, .carousel .item, .carousel .item .active, .carousel-inner {
    height:100%;
}

/* Since positioning the image, we need to help out the caption */
.carousel-caption {
    z-index: 10;
}

.carousel-inner {
    height:100%;
}

/* Declare heights */
.carousel .item {
    height:100%;
}

.carousel .fill {
    width:100%;
    height:100%;
    background-size:cover;
    background-position: center center;
}

.carousel .first-slide {
    background-image:url("https://images.unsplash.com/photo-1466026396492-d69f93144acc")
}

.carousel .second-slide {
    background-image:url("https://images.unsplash.com/photo-1503629473531-b06530731f51")
}

.carousel .third-slide {
    background-image:url("https://images.unsplash.com/photo-1497996377197-e4b9024658a4")
}


/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
    margin-bottom: 20px;
    text-align: center;
}
.marketing h2 {
    font-weight: normal;
}
.marketing .col-lg-4 p {
    margin-right: 10px;
    margin-left: 10px;
}


/* Featurettes
------------------------- */

.featurette-divider {
    margin: 80px 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
    font-weight: 300;
    line-height: 1;
    letter-spacing: -1px;
}


/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 768px) {
    /* Navbar positioning foo */
    .navbar-wrapper {
        margin-top: 20px;
    }
    .navbar-wrapper .container {
        padding-right: 15px;
        padding-left: 15px;
    }
    .navbar-wrapper .navbar {
        padding-right: 0;
        padding-left: 0;
    }

    /* The navbar becomes detached from the top, so we round the corners */
    .navbar-wrapper .navbar {
        border-radius: 4px;
    }

    /* Bump up size of carousel content */
    .carousel-caption p {
        margin-bottom: 20px;
        font-size: 21px;
        line-height: 1.4;
    }

    .featurette-heading {
        font-size: 50px;
    }
}

@media (min-width: 992px) {
    .featurette-heading {
        margin-top: 120px;
    }
}

.swiper-container {width:100%; height: auto;}
.swiper-slide {text-align: center;font-size: 18px;background: #fff;width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;}
.swiper-slide img {width: 100%;height: auto;}
.swiper-pagination {float: left;margin: 7px 0 0 25px;}
.slider-container {width:100%;height: auto;}
.slider-slide {text-align: center;font-size: 18px;background: #fff;width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;}
.slider-slide img {width: 100%;height: auto; }
.slider-pagination {margin-top: 15px;}
::-webkit-scrollbar {width: 5px;}
::-webkit-scrollbar-track {background: #f1f1f1;}
::-webkit-scrollbar-thumb {background: #888;}
::-webkit-scrollbar-thumb:hover {background: #444;}

.nav-menu {
    a {
        padding: 0;
        margin: 0;
        color: $dark;
        &:hover {
            color: $primary !important;
        }
    }
}

.footer-menu, .nav-menu {
    a:not(:last-child)::after {
        content: '|';
        padding: 0 10px;
        &:hover {
            color: $dark !important;
        }
    }
    .lang-item::after {
        padding: 0 3px !important;
    }
}

@media (max-width: 436px) {
    .mobile-slider-caption {
        left: 0 !important;
        top: 0 !important;
        width: 100% !important;
        height: 100% !important;
        margin: 0 !important;
    }
}

.bg-gray {
    background-color: #eaeaea;
}

.fw-500 {
    font-weight: 500 !important;
}

.fs-18px {
    font-size: 18px !important;
}

header nav .nav-menu {
    font-size: 18px !important;
    .active {
        font-weight: 500;
        color: $primary !important;
        &:not(:last-child)::after {
            color: $dark !important;
        }
    }
}

.product-menu .nav-link {
    color: #fff !important;
    font-size: 14px !important;
}

.product-menu .nav-link {
    padding-right: 0 !important;
    padding-left: 0 !important;
}


@media (min-width: 992px) {
    .product-menu .nav-link::after {
        content: '|';
        padding-right: 14px;
        padding-left: 15px;
        &:hover {
            color: #fff !important;
        }
    }

}

.product-menu .last-item a::after {
    content: '' !important;
}

.border-primary {
    border-color: $primary !important;
}

.list-primary {
    margin: 0;
    padding: 0;
    li {
        line-height: 18px;
        margin-bottom: 7px;
        &::marker {
            color: $primary;
            font-size: 1.7em;
        }
    }
}

.recipes-item {
    line-height: 15px;
    .recipes-ingredients {
        font-size: 14px;
    }
    .recipes-preparation {
        font-size: 12px;
    }
}


.mobile-view .active {
    font-weight: 500;
    color: #720d2c !important;
}

