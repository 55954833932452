// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Roboto', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$primary: #720d2c;
$link-color: $primary !default;
$border-radius: 0;
